/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CompareTableBySKU.vue?vue&type=template&id=cc98351e&"
import script from "./CompareTableBySKU.vue?vue&type=script&lang=js&"
export * from "./CompareTableBySKU.vue?vue&type=script&lang=js&"
import style0 from "./CompareTableBySKU.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports